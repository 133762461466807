import Login from "./routes/Login";
import AdminPanel from "./routes/AdminPanel";
import React from 'react';
import axios from "axios";
import * as backendModule from "./modules/backendModule";

function App() {
  const [logged, setLogged] = React.useState(false);
  const [userData, setUserData] = React.useState([]);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setLogged(true);
        setUserData(res.data.data.UserInfo);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className="route__contentWrapper">
      {
        logged ?
        <AdminPanel userData={userData} />
        : <Login setLogged={setLogged} />
      }
    </div>
  );
}

export default App;