import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import { countriesFull as countries } from "../../modules/countryModules";
import { flagColors, flagNameColors } from "../../modules/colorsModule";
import { checkMail } from "../../modules/miscModule";
import { itemCountFormatter } from '../../modules/miscModule';

import YesNoModal from "../../components/Modals/YesNoModal";
import Spinner from "../../components/Spinner";
import SimpleDropdown from "../../components/SimpleDropdown";
import Dropdown from "../../components/Dropdown";

let usersTimeout = null;
const Users = () => {
    const [data, setData] = React.useState();

    const [searchVisible, setSearchVisible] = React.useState();
    const [ordersVisible, setOrdersVisible] = React.useState();
    const [search, setSearch] = React.useState("");
    const [order, setOrder] = React.useState([]);

    const userFlags = [
        {name: 'isAdmin', friendlyName: 'Admin'},
        {name: 'isCCagent', friendlyName: 'CC agent'}
    ]

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const mainRef = React.useRef();
    const firstTimeRef = React.useRef(true);

    const deleteUser = (e, usr) => {
        const performDelete = (arg) => {
            arg.errorMessage("");
            arg.disabledAll(true);
            arg.spinner(true);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/deleteUser`,
                data: {
                    id: usr.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    arg.close();
                    getData();
                } else {
                    arg.errorMessage("There was an error while deleting a client!");
                };
            }).catch(() => {
                arg.errorMessage("Server timed out!");
            }).finally(() => {
                arg.spinner(false);
                arg.disabledAll(false);
            });
        };

        animateBox(e, <YesNoModal
            heading="Da li ste sigurni?"
            text={<>
                Da li želite obrisati <span style={{ color: "#EF9500" }}>{`${usr.FirstName} ${usr.LastName}`}</span>?<br />
                Ova radnja neće moći biti vraćena!
            </>}
            isRightButtonNormal={true}
            buttonRightCallback={arg => {
                performDelete(arg);
            }}
            buttonLeftText='Ne'
            buttonRightText='Da'
        />);
    };

    const getData = () => {
        let splitFlags = search.split(" ").map(t => t.trim()).filter(f => f).map(sf => {
            let f = userFlags.find(u => u.friendlyName.toLowerCase() === sf.toLowerCase());
            if (f) return f.name;
            return null;
        }).filter(f => f);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: search ? [{
                    or: [
                        { name: "ID", op: "like", value: search },
                        { name: "Username", op: "like", value: search },
                        { name: "Email", op: "like", value: search },
                        { name: "--FirstLastName", value: search.split(" ") },
                        ...splitFlags.map(sf => {
                            return { name: `Flags:${sf}`, op: "eq", value: true }
                        })
                    ]
                }] : [],
                orders: order
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (firstTimeRef.current) firstTimeRef.current = false;
        clearTimeout(usersTimeout);

        usersTimeout = setTimeout(() => {
            getData();
        }, firstTimeRef.current ? 0 : 400);
    }, [search, order]);

    return <div className="route__users" ref={mainRef}>
        <div className="route__users__head">
            <div className="route__users__head__left">
                <span>Korisnici</span>
                {data?.status === "ok" && <div className="route__users__head__left__circle">{itemCountFormatter(data.data.length)}</div>}
            </div>
            <div className="route__users__head__right">
                <div className="route__users__head__right__btnCreate" onClick={e => {
                    animateBox(e, <AddUser onChange={() => getData()} />)
                }}>
                    <img src="/images/header_add.svg" />
                    <span>Novi korisnik</span>
                </div>

                <div className={`route__users__head__right__search ${searchVisible ? "route__users__head__right__search--active" : ""}`}>
                    <img src="/images/head_search.svg" className="route__users__head__right__search__icon1" />
                    <input type="text" placeholder="Pretražuj..." value={search} onChange={e => setSearch(e?.target?.value)} />
                    <div className="route__users__head__right__search__close" style={{
                        backgroundImage: "url('/images/head_close.svg')"
                    }} onClick={() => {
                        setSearch("");
                        setSearchVisible(false);
                    }}></div>
                </div>
                <div className={`route__users__head__right__btn ${!searchVisible ? "route__users__head__right__btn--active" : ""}`} style={{ backgroundImage: "url('/images/head_filter.svg')" }} onClick={() => setSearchVisible(true)}></div>

                <SimpleDropdown
                    className={`route__users__head__right__order ${ordersVisible ? "route__users__head__right__order--active" : ""}`}
                    heading="Order..."
                    data={[
                        { name: "ID uzlazno", value: [{ name: "ID", order: "asc" }] },
                        { name: "ID silazno", value: [{ name: "ID", order: "desc" }] },

                        { name: "Korisničko ime uzlazno", value: [{ name: "Username", order: "asc" }] },
                        { name: "Korisničko ime silazno", value: [{ name: "Username", order: "desc" }] },

                        { name: "Ime uzlazno", value: [{ name: "FirstName", order: "asc" }] },
                        { name: "Ime silazno", value: [{ name: "FirstName", order: "desc" }] },

                        { name: "Prezime uzlazno", value: [{ name: "LastName", order: "asc" }] },
                        { name: "Prezime silazno", value: [{ name: "LastName", order: "desc" }] },

                        { name: "Email uzlazno", value: [{ name: "Email", order: "asc" }] },
                        { name: "Email silazno", value: [{ name: "Email", order: "desc" }] },

                        { name: "Datum kreiranja uzlazno", value: [{ name: "createdAt", order: "asc" }] },
                        { name: "Datum kreiranja silazno", value: [{ name: "createdAt", order: "desc" }] },
                    ]}
                    setSelected={e => setOrder(e?.value)}
                    xCallback={arg => {
                        arg.clearSelected();
                        setOrdersVisible(false);
                        setOrder([]);
                    }}
                    headStyle={{ boxShadow: "none", border: "0.5px solid #A0AEC0", borderRadius: "5px" }}
                />
                <div className={`route__users__head__right__btn ${!ordersVisible ? "route__users__head__right__btn--active" : ""}`} style={{ backgroundImage: "url('/images/head_order.svg')" }} onClick={() => setOrdersVisible(true)}></div>
            </div>
        </div>

        <div className="route__users__table">
            <p className="route__users__table__head">ID</p>
            <p className="route__users__table__head">Korisničko ime</p>
            <p className="route__users__table__head">Ime</p>
            <p className="route__users__table__head">Email</p>
            <p className="route__users__table__head">Uloga</p>
            <p className="route__users__table__head">Uredi</p>
            <p className="route__users__table__head">Obriši</p>
            <p className="route__users__table__line"></p>

            {data ? <>
                {data.status === "ok" ? data.data.map((elem, elemIdx) => <>
                    <p className="route__users__table__text">{elem.ID}</p>
                    <p className="route__users__table__text">{elem.Username}</p>
                    <p className="route__users__table__text">{`${elem.FirstName ?? "?"} ${elem.LastName ? elem.LastName.charAt(0) : "?"}.`}</p>
                    <p className="route__users__table__text">{elem.Email}</p>
                    <p className="route__users__table__role">{elem.ProcessedFlags.map(flg => <span style={{ ...flagNameColors(flg) }}>
                        {flg}
                    </span>)}</p>
                    <p className="route__users__table__icon" style={{ backgroundImage: "url('/images/table_edit.svg')" }} onClick={(e) => animateBox(e, <AddUser onChange={() => getData()} edit={elem} />)}></p>
                    <p className="route__users__table__icon" style={{
                        backgroundImage: "url('/images/table_delete.svg')",
                        opacity: userInfoSelector.ID === elem.ID ? 0 : 1,
                        pointerEvents: userInfoSelector.ID === elem.ID ? "none" : "all"
                    }} onClick={e => deleteUser(e, elem)}></p>
                    {elemIdx !== data.data.length - 1 && <p className="route__users__table__line"></p>}
                </>) : <p className="route__users__table__error">There was an error while fetching the data!</p>}
            </> : <p className="route__users__table__spinner">
                <Spinner color="#FCA311" style={{ width: "32px", height: "32px" }} />
            </p>}
        </div>
    </div>
};

const AddUser = (props) => {
    const [selectedFlags, setSelectedFlags] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadError: false,
        inputs: []
    });

    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const phoneNumberRef = React.useRef();
    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const emailRef = React.useRef();

    const userFlags = [
        {name: 'isAdmin', friendlyName: 'Admin'},
        {name: 'isCCagent', friendlyName: 'CC agent'}
    ]

    const addUser = () => {
        if (spinner) return;
        setInfoP(i => { return { ...i, hadError: false, inputs: [] } });

        let flags = selectedFlags.reduce((acc, val) => {
            acc[val] = true;
            return acc;
        }, {});

        for (let item of userFlags) {
            if (!flags[item.name]) flags[item.name] = false;
        };

        const data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            email: emailRef.current.value,
            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            PhoneNumber: phoneNumberRef.current.value,
            Country: selectedCountry,

            flags: { ...flags }
        };

        if (props.edit) data["id"] = props.edit.ID;

        if (!data.FirstName) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["FirstName"], error: "Ime ne može biti prazno" } });
        };
        if (!data.LastName) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["LastName"], error: "Prezime ne može biti prazno" } });
        };
        if (!data.PhoneNumber) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["PhoneNumber"], error: "Broj telefona ne može biti prazan" } });
        };
        if (!data.Country) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["Country"], error: "Država ne može biti prazna" } });
        };
        if (!data.username) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["Username"], error: "Korisničko ime ne može biti prazno" } });
        };
        if (!data.password && !props.edit) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["Password"], error: "Lozinka ne može biti prazna" } });
        };
        if (!data.email) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["Email"], error: "Email ne može biti prazan" } });
        };

        data.PhoneNumber = Number(data.PhoneNumber);
        if (isNaN(data.PhoneNumber)) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["PhoneNumber"], error: "Broj telefona mora biti broj" } });
        };

        if (!checkMail(data.email)) {
            return setInfoP(i => { return { ...i, hadError: true, inputs: ["Email"], error: "Email neispravan" } });
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/${props.edit ? "editUser" : "createNewUser"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            } else {
                return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: `Error while ${props.edit ? "editing" : "creating"} user, Username or Email already exists.` } });
            };
        }).catch(() => {
            return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out!" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!props.edit) return;
        if (!firstNameRef.current) return;

        firstNameRef.current.value = props.edit.FirstName;
        lastNameRef.current.value = props.edit.LastName;
        phoneNumberRef.current.value = props.edit.PhoneNumber;
        usernameRef.current.value = props.edit.Username;
        emailRef.current.value = props.edit.Email;
        setSelectedCountry(props.edit.Country);
        setSelectedFlags(Object.keys(props.edit.Flags).map(key => {
            if (props.edit.Flags[key] === true) return key;
            return null
        }).filter(f => f));
    }, [firstNameRef.current]);

    return <div className="route__users__add">
        <div className="route__users__add__wrap">
            <div className="route__users__add__wrap__top">
                <p className="route__users__add__wrap__top__head">{!props.edit && <img alt="" src="/images/header__addBlack.svg" />}{props.edit ? "Uredi korisnika" : "Novi korisnik"}</p>
                <div className="route__users__add__wrap__top__btn" style={{
                    backgroundImage: `url("/images/head_close.svg")`
                }} onClick={() => props.onClose()}>
                </div>
            </div>

            <div className="route__users__add__wrap__bottom">
                <div className={`route__users__add__wrap__bottom__input ${infoP.inputs.includes("FirstName") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>Ime</label>
                    <input ref={firstNameRef} type="text" placeholder="Ime" />
                </div>

                <div className={`route__users__add__wrap__bottom__input ${infoP.inputs.includes("LastName") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>Prezime</label>
                    <input ref={lastNameRef} type="text" placeholder="Prezime" />
                </div>

                <div className={`route__users__add__wrap__bottom__input ${infoP.inputs.includes("PhoneNumber") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>Broj telefona</label>
                    <input ref={phoneNumberRef} type="text" placeholder="Broj telefona" />
                </div>

                <div className={`route__users__add__wrap__bottom__input ${infoP.inputs.includes("Country") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>Država</label>
                    <Dropdown
                        accent="rgba(0, 0, 0, 0.2)"
                        theme="light"
                        inlinePlaceholder="Država"
                        data={countries.map(c => {
                            return { name: c.name, value: c.code }
                        })}
                        onChange={e => setSelectedCountry(e?.value)}
                        selected={countries.indexOf(countries.find(c => c.code === selectedCountry))}
                    />
                </div>

                <div className={`route__users__add__wrap__bottom__input ${props.edit ? "route__users__add__wrap__bottom__input--disabled" : ""} ${infoP.inputs.includes("Username") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>Korisničko ime</label>
                    <input ref={usernameRef} type="text" placeholder="Korisničko ime" />
                </div>

                <div className={`route__users__add__wrap__bottom__input ${infoP.inputs.includes("Password") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>Lozinka</label>
                    <input ref={passwordRef} type="password" placeholder="Lozinka" />
                </div>

                <div className={`route__users__add__wrap__bottom__input ${infoP.inputs.includes("Email") ? "route__users__add__wrap__bottom__input--error" : ""}`}>
                    <label>E-mail</label>
                    <input ref={emailRef} type="text" placeholder="E-mail" />
                </div>

                <div className="route__users__add__wrap__bottom__roles">
                    <label>Uloga ({selectedFlags.length})</label>
                    <div className="route__users__add__wrap__bottom__roles__list">
                        {userFlags.map(flg => {
                            return <span className={`route__users__add__wrap__bottom__roles__list__item`} style={{
                                ...(selectedFlags.includes(flg.name) ? flagColors(flg.name) : {})
                            }} onClick={() => {
                                if (selectedFlags.includes(flg.name)) {
                                    setSelectedFlags(sf => sf.filter(sff => sff !== flg.name));
                                } else {
                                    setSelectedFlags(sf => [...sf, flg.name]);
                                };
                            }}>{flg.friendlyName}</span>
                        })}
                    </div>
                </div>

                <div className="route__users__add__wrap__bottom__btns">
                    <p className="route__users__add__wrap__bottom__btns__infoP" style={{
                        opacity: infoP.hadError ? 1 : 0
                    }}>
                        {infoP.error}
                    </p>
                    <div className="route__users__add__wrap__bottom__btns__btn" onClick={addUser} style={{
                        background: spinner ? "#EF9500" : null,
                        pointerEvents: spinner ? "none" : "all"
                    }}>
                        {spinner ? <Spinner style={{ width: "32px", height: "32px" }} color="white" /> : "Sačuvaj korisnika"}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Users;