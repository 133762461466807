import React from 'react';
import "./index.css";

import Dashboard from '../../components/Dashboard';
import Kanban from "../../components/Kanban";
import Documents from "../../components/Users";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampActions";

export default function AdminPanel(props) {
  const [activeTab, setActiveTab] = React.useState(props.userData?.Flags?.isAdmin ? 'dashboard' : 'kanban');
  const [nav, setNav] = React.useState(false);

  const curDispatch = useDispatch();

  const logout = () => {
    axios({
      method: "GET",
      url: `${backendModule.backendURL}/auth/logout`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      curDispatch(timestampActions.updateTimestamp());
      window.location.reload();
    }).catch(() => {
    }).finally(() => {
    });
  }

  return (
    <div className='route__adminPanel'>
      <div className='route__adminPanel__sidebar'>
        <h1>Monscale CRM</h1>
        <small>v0.0.1</small>
        {props.userData?.Flags?.isAdmin && <p onClick={() => setActiveTab('dashboard')} style={{ background: activeTab === 'dashboard' ? '#F3F3F3' : '' }}>Statistika</p>}
        <p onClick={() => setActiveTab('kanban')} style={{ background: activeTab === 'kanban' ? '#F3F3F3' : '' }}>Kanban</p>
        {props.userData?.Flags?.isAdmin && <p onClick={() => setActiveTab('users')} style={{ background: activeTab === 'users' ? '#F3F3F3' : '' }}>Korisnici</p>}
        <strong onClick={() => logout()}>Odjavi se</strong>
      </div>
      <div className='route__adminPanel__sidebar mobile'>
        <h1>Monscale CRM</h1>
        <small>v0.0.1</small>
        <img onClick={() => setNav(n => !n)} src='images/hamburger.svg' alt='' />
        <div className={`route__adminPanel__sidebar mobile mob-nav ${nav ? 'route__adminPanel__sidebar mobile mob-nav mob-nav-active' : ''}`}>
          {props.userData?.Flags?.isAdmin && <p onClick={() => {setActiveTab('dashboard'); setNav(false)}} style={{ background: activeTab === 'dashboard' ? '#F3F3F3' : '' }}>Statistika</p>}
          <p onClick={() => {setActiveTab('kanban'); setNav(false)}} style={{ background: activeTab === 'kanban' ? '#F3F3F3' : '' }}>Kanban</p>
          {props.userData?.Flags?.isAdmin && <p onClick={() => {setActiveTab('users'); setNav(false)}} style={{ background: activeTab === 'users' ? '#F3F3F3' : '' }}>Korisnici</p>}
          <strong onClick={() => logout()}>Odjavi se</strong>
        </div>
      </div>
      <div className='route__adminPanel__body'>
        {activeTab === 'dashboard' && <Dashboard />}
        {activeTab === 'kanban' && <Kanban userData={props.userData} />}
        {activeTab === 'users' && <Documents />}
      </div>
    </div>
  )
}
