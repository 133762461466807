import React from 'react';
import "./index.css";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import moment from 'moment/moment';

export default function Kanban(props) {
  const [spinner, setSpinner] = React.useState(true);
  const [secSpinner, setSecSpinner] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState({ name: 'Sve', value: -1 });
  const [dropdown, setDropdown] = React.useState(false);

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {
        filters: selectedFilter.value >= 0 ? [{ name: 'Status', op: 'eq', value: selectedFilter.value }] : null
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const changeStatus = (id, status) => {
    setSecSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/changeStatus`,
      data: {
        ID: id,
        Status: status
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getData();
      } else {
        return setMessage('Došlo je do greške prilikom promjene statusa lead-a...');
      };
    }).catch(() => {
      return setMessage('Došlo je do greške prilikom promjene statusa lead-a...');
    }).finally(() => {
      setSecSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
  }, [selectedFilter]);

  return (
    <div className='route__kanban'>
      <div className='route__kanban__header'>
        <p>Kanban</p>
        <div className='route__kanban__header__dropdown'>
          <div onClick={() => setDropdown(d => !d)} className={`route__kanban__header__dropdown__head ${dropdown ? 'route__kanban__header__dropdown__head--active' : ''}`}>
            <p>{selectedFilter.name}</p>
          </div>
          <div className={`route__kanban__header__dropdown__body ${dropdown ? 'route__kanban__header__dropdown__body--active' : ''}`}>
            <p onClick={() => { setSelectedFilter({ name: 'Novi upiti', value: 0 }); setDropdown(false) }}>Novi upiti</p>
            <p onClick={() => { setSelectedFilter({ name: 'Potvrđeno', value: 1 }); setDropdown(false) }}>Potvrđeno</p>
            <p onClick={() => { setSelectedFilter({ name: 'U isporuci', value: 2 }); setDropdown(false) }}>U isporuci</p>
            <p onClick={() => { setSelectedFilter({ name: 'Naplaćeno', value: 3 }); setDropdown(false) }}>Naplaćeno</p>
            <p onClick={() => { setSelectedFilter({ name: 'Odbijeno', value: 4 }); setDropdown(false) }}>Odbijeno</p>
            <p onClick={() => { setSelectedFilter({ name: 'Pozvati ponovo', value: 5 }); setDropdown(false) }}>Pozvati ponovo</p>
            <p onClick={() => { setSelectedFilter({ name: 'Sve', value: -1 }); setDropdown(false) }}>Sve</p>
          </div>
        </div>
      </div>
      {message && <p style={{ color: 'red' }}>{message}</p>}
      {spinner && <Spinner align='center' style={{ marginTop: '20px' }} color='gray' />}
      {!message && !spinner && selectedFilter.value === -1 && <div className='route__kanban__content'>
        <div className='route__kanban__content__column'>
          <p style={{ background: '#13213C' }}>Novi upiti</p>
          <div className='route__kanban__content__column__list'>
            {
              data?.data?.map((item) => {
                if (item.Status === 0) {
                  return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Broj: {item.Phone}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} KM</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: '#08b555c2' }}>Potvrđeno</p>
          <div className='route__kanban__content__column__list'>
            {
              data?.data?.map((item) => {
                if (item.Status === 1) {
                  return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Broj: {item.Phone}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} KM</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: '#EF9500' }}>U isporuci</p>
          <div className='route__kanban__content__column__list'>
            {
              data?.data?.map((item) => {
                if (item.Status === 2) {
                  return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Broj: {item.Phone}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} KM</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(33 163 9 / 86%)' }}>Naplaćeno</p>
          <div className='route__kanban__content__column__list'>
            {
              data?.data?.map((item) => {
                if (item.Status === 3) {
                  return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Broj: {item.Phone}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} KM</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(233 43 43 / 88%)' }}>Odbijeno</p>
          <div className='route__kanban__content__column__list'>
            {
              data?.data?.map((item) => {
                if (item.Status === 4) {
                  return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Broj: {item.Phone}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} KM</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
          </div>
        </div>
        <div className='route__kanban__content__column'>
          <p style={{ background: 'rgb(76 56 124)' }}>Pozvati ponovo</p>
          <div className='route__kanban__content__column__list'>
            {
              data?.data?.map((item) => {
                if (item.Status === 5) {
                  return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                    <p>ID: #{item.ID}</p>
                    <p>Ime: {item.Name}</p>
                    <p>Broj: {item.Phone}</p>
                    <p>Proizvod: {item.Product}</p>
                    <p>Država: {item.Country}</p>
                    <p>Količina: {item.Quantity}</p>
                    <p>Cijena: {item.Price} KM</p>
                    <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                    {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                      <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                      <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                      <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                      <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                    </div>}
                    {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                  </div>
                }
              })
            }
          </div>
        </div>
      </div>}
      {
        selectedFilter.value >= 0 && <div className='route__kanban__content'>
          <div className='route__kanban__content__column'>
            <p style={{ background: 'rgb(133 133 133)' }}>Filterisano: {selectedFilter.name}</p>
            <div className='route__kanban__content__column__list'>
              {
                data?.data?.map((item) => {
                  if (item.Status === selectedFilter.value) {
                    return <div onClick={(e) => animateBox(e, <LeadInfo user={props.userData?.FirstName} getAll={getData} lead={item} />)} className='route__kanban__content__column__list__item'>
                      <p>ID: #{item.ID}</p>
                      <p>Ime: {item.Name}</p>
                      <p>Broj: {item.Phone}</p>
                      <p>Proizvod: {item.Product}</p>
                      <p>Država: {item.Country}</p>
                      <p>Količina: {item.Quantity}</p>
                      <p>Cijena: {item.Price} KM</p>
                      <p>Pristiglo: {moment(item.createdAt).format('DD.MM.YYYY hh:mm:ss A')}</p>
                      {!secSpinner && <div className='route__kanban__content__column__list__item__status'>
                        <strong onClick={() => changeStatus(item.ID, 0)} style={{ background: 'rgb(19, 33, 60)' }}>Novi upiti</strong>
                        <strong onClick={() => changeStatus(item.ID, 1)} style={{ background: 'rgba(8, 181, 85, 0.76)' }}>Potvrđeno</strong>
                        <strong onClick={() => changeStatus(item.ID, 2)} style={{ background: 'rgb(239, 149, 0)' }}>U isporuci</strong>
                        <strong onClick={() => changeStatus(item.ID, 3)} style={{ background: 'rgba(33, 163, 9, 0.86)' }}>Naplaćeno</strong>
                        <strong onClick={() => changeStatus(item.ID, 4)} style={{ background: 'rgba(233, 43, 43, 0.88)' }}>Odbijeno</strong>
                        <strong onClick={() => changeStatus(item.ID, 5)} style={{ background: 'rgb(76, 56, 124)' }}>Pozvati ponovo</strong>
                      </div>}
                      {secSpinner && <Spinner color='white' style={{ width: '18px' }} align='center' />}
                    </div>
                  }
                })
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const LeadInfo = (props) => {
  const [data, setData] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [btnSpinner, setBtnSpinner] = React.useState(false);
  const [error, setError] = React.useState(null);

  const commentRef = React.useRef(null);
  const quantityRef = React.useRef(null);
  const priceRef = React.useRef(null);

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leadActivity/getAllActivities`,
      data: {
        filters: [{ name: 'LeadID', op: 'eq', value: props?.lead?.ID }]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setMessage('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setMessage('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const addActivity = () => {
    if (!commentRef.current.value || !quantityRef.current.value || !priceRef.current.value) return setError('Sva polja moraju biti popunjena!');

    setBtnSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leadActivity/addActivity`,
      data: {
        User: props.user,
        LeadID: props?.lead?.ID,
        Comment: commentRef.current.value,
        Quantity: quantityRef.current.value,
        Price: priceRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll();
        getData();
        commentRef.current.value = '';
        quantityRef.current.value = '';
        priceRef.current.value = '';
        setError(null);
      } else {
        return setError('Došlo je do greške prilikom slanja podataka...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setBtnSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
  }, [props?.lead?.ID]);

  return <div className='route__kanban__leadInfo'>
    <div className='route__kanban__leadInfo__wrapper'>
      <div className='route__kanban__leadInfo__wrapper__header'>
        <h1>Aktivnost na lead-u sa ID: #{props.lead.ID}</h1>
        <img onClick={props.onClose} src='images/head_close.svg' alt='' />
      </div>
      <div className='route__kanban__leadInfo__wrapper__body'>
        <div className='route__kanban__leadInfo__wrapper__body__input'>
          <textarea ref={commentRef} placeholder='Napiši komentar (ime, adresa, broj telefona...)'></textarea>
        </div>
        <div className='route__kanban__leadInfo__wrapper__body__quantity'>
          <p>Količina?</p>
          <input ref={quantityRef} placeholder='Unesi količinu' type='text' />
          <p>Po cijeni?</p>
          <input ref={priceRef} placeholder='Unesi cijenu' type='text' />
          <button onClick={() => addActivity()}>{!btnSpinner ? 'Sačuvaj' : <Spinner align='center' color='white' style={{ width: '24px', height: '24px' }} />}</button>
        </div>
        {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
        {
          !message && !spinner ? data?.data?.map(item => {
            return <div className='route__kanban__leadInfo__wrapper__body__item'>
              <strong>{item.User}</strong>
              <p>{item.Comment}</p>
              <small>Količina: {item.Quantity} Po cijeni: {item.Price} KM</small>
            </div>
          }) : ''
        }
        {message && !spinner ? <p style={{ color: 'red' }}>{message}</p> : ''}
        {spinner ? <Spinner style={{ width: '32px' }} color='#EF9500' align='center' /> : ''}
      </div>
    </div>
  </div>
}