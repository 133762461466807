import React from 'react';
import "./index.css";
import Spinner from "../../components/Spinner";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import moment from "moment";

export default function Dashboard() {
  const [data, setData] = React.useState([]);
  const [monthData, setMonthData] = React.useState([]);
  const [dayData, setDayData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [monthSpinner, setMonthSpinner] = React.useState(true);
  const [daySpinner, setDaySpinner] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [products, setProducts] = React.useState([]);

  const returnMoney = (status, data) => {
    let tmp = 0;
    data?.data?.map((item, index) => {
      if (item.Status === status) {
        tmp = tmp + Number(item.Price);
      }
    })
    return Number(tmp).toFixed(2) + ' KM';
  }

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/leads/getAllLeads`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setError('Došlo je do greške prilikom dohvatanja podataka...');
      };
    }).catch(() => {
      return setError('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }, []);

  React.useEffect(() => {
    axios({
      method: "GET",
      url: `/products.json`,
      data: {},
      ...backendModule.axiosConfig
    }).then(res => {
      setProducts(res.data);
    }).catch(() => {
      return setError('Došlo je do greške prilikom dohvatanja podataka...');
    });
  }, []);

  React.useEffect(() => {
    if (data?.data?.length > 0) {
      data?.data?.map((item, index) => {
        if (!moment(item.createdAt).isBefore(moment(), "day") && !moment(item.createdAt).isAfter(moment(), "day")) {
          setDayData(prev => {
            return [
              ...prev,
              item
            ]
          });
        }
        if (index === data?.data?.length - 1) {
          setDaySpinner(false);
        }
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (data?.data?.length > 0) {
      data?.data?.map((item, index) => {
        if (moment(item.createdAt).isSame(moment(), 'month')) {
          setMonthData(prev => {
            return [
              ...prev,
              item
            ]
          });
        }
        if (index === data?.data?.length - 1) {
          setMonthSpinner(false);
        }
      });
    }
  }, [data]);

  return (
    <div className='route__dashboard'>
      <div className='route__dashboard__header'>
        <p>Statistika</p>
      </div>
      {spinner || monthSpinner || daySpinner ? <Spinner align='center' color='#EF9500' style={{ width: '32px' }} /> : ''}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!spinner && !monthSpinner && !daySpinner && !error && <div className='route__dashboard__money'>
        <div className='route__dashboard__money__keypi'>
          <h1>PROMET UKUPNO</h1>
          <p>U NOVIM UPITIMA: {returnMoney(0, data)}</p>
          <p>U POTVRĐENIM UPITIMA: {returnMoney(1, data)}</p>
          <p>U ISPORUCI: {returnMoney(2, data)}</p>
          <p>U NAPLAĆENIM UPITIMA: {returnMoney(3, data)}</p>
          <p>U ODBIJENIM UPITIMA: {returnMoney(4, data)}</p>
          <p>U POZVATI PONOVO: {returnMoney(5, data)}</p>
          <p>UPITA: {data?.data?.length}</p>
          <p>POTVRĐENIH UPITA: {data?.data?.filter(lead => lead.Status === 1).length}</p>
        </div>
        <div className='route__dashboard__money__keypi'>
          <h1>PROMET TEKUĆI MJESEC</h1>
          <p>U NOVIM UPITIMA: {returnMoney(0, { data: monthData })}</p>
          <p>U POTVRĐENIM UPITIMA: {returnMoney(1, { data: monthData })}</p>
          <p>U ISPORUCI: {returnMoney(2, { data: monthData })}</p>
          <p>U NAPLAĆENIM UPITIMA: {returnMoney(3, { data: monthData })}</p>
          <p>U ODBIJENIM UPITIMA: {returnMoney(4, { data: monthData })}</p>
          <p>U POZVATI PONOVO: {returnMoney(5, { data: monthData })}</p>
          <p>UPITA: {monthData?.length}</p>
          <p>POTVRĐENIH UPITA: {monthData?.filter(lead => lead.Status === 1).length}</p>
        </div>
        <div className='route__dashboard__money__keypi last'>
          <h1>PROMET DANAS</h1>
          <p>U NOVIM UPITIMA: {returnMoney(0, { data: dayData })}</p>
          <p>U POTVRĐENIM UPITIMA: {returnMoney(1, { data: dayData })}</p>
          <p>U ODBIJENIM UPITIMA: {returnMoney(4, { data: dayData })}</p>
          <p>U POZVATI PONOVO: {returnMoney(5, { data: dayData })}</p>
          <p>UPITA: {dayData?.length}</p>
          <p>POTVRĐENIH UPITA: {dayData?.filter(lead => lead.Status === 1).length}</p>
        </div>
      </div>}
      {!spinner && !monthSpinner && !daySpinner && !error && <h1 className='route__dashboard__h1'>ŽIVOTNI VIJEK STATISTIKA</h1>}
      {!spinner && !monthSpinner && !daySpinner && !error && <div className='route__dashboard__leads'>
        <div className='route__dashboard__leads__keypi'>
          <h1>Svi upiti</h1>
          <p>Ukupno upita: {data?.data?.length}</p>
          <div className='route__dashboard__leads__keypi__content'>
            {
              products?.map(product => {
                return <p className='route__dashboard__leads__keypi__content__product'>
                  {product}: {data?.data?.filter(lead => lead.Product === product).length}
                </p>
              })
            }
          </div>
        </div>
        <div className='route__dashboard__leads__keypi'>
          <h1>Potvrđeni upiti</h1>
          <p>Ukupno upita: {data?.data?.filter(lead => lead.Status === 1).length}</p>
          <div className='route__dashboard__leads__keypi__content'>
            {
              products?.map(product => {
                return <p className='route__dashboard__leads__keypi__content__product'>
                  {product}: {data?.data?.filter(lead => lead.Product === product && lead.Status === 1).length}
                </p>
              })
            }
          </div>
        </div>
        <div className='route__dashboard__leads__keypi'>
          <h1>Upiti u isporuci</h1>
          <p>Ukupno upita: {data?.data?.filter(lead => lead.Status === 2).length}</p>
          <div className='route__dashboard__leads__keypi__content'>
            {
              products?.map(product => {
                return <p className='route__dashboard__leads__keypi__content__product'>
                  {product}: {data?.data?.filter(lead => lead.Product === product && lead.Status === 2).length}
                </p>
              })
            }
          </div>
        </div>
        <div className='route__dashboard__leads__keypi'>
          <h1>Naplaćenih upita</h1>
          <p>Ukupno upita: {data?.data?.filter(lead => lead.Status === 3).length}</p>
          <div className='route__dashboard__leads__keypi__content'>
            {
              products?.map(product => {
                return <p className='route__dashboard__leads__keypi__content__product'>
                  {product}: {data?.data?.filter(lead => lead.Product === product && lead.Status === 3).length}
                </p>
              })
            }
          </div>
        </div>
        <div className='route__dashboard__leads__keypi'>
          <h1>Odbijenih upita</h1>
          <p>Ukupno upita: {data?.data?.filter(lead => lead.Status === 4).length}</p>
          <div className='route__dashboard__leads__keypi__content'>
            {
              products?.map(product => {
                return <p className='route__dashboard__leads__keypi__content__product'>
                  {product}: {data?.data?.filter(lead => lead.Product === product && lead.Status === 4).length}
                </p>
              })
            }
          </div>
        </div>
        <div className='route__dashboard__leads__keypi'>
          <h1>Upiti u pozvati ponovo</h1>
          <p>Ukupno upita: {data?.data?.filter(lead => lead.Status === 5).length}</p>
          <div className='route__dashboard__leads__keypi__content'>
            {
              products?.map(product => {
                return <p className='route__dashboard__leads__keypi__content__product'>
                  {product}: {data?.data?.filter(lead => lead.Product === product && lead.Status === 5).length}
                </p>
              })
            }
          </div>
        </div>
      </div>}
    </div>
  )
}
